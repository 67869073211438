import React, { Component } from "react"
import { Link, navigate } from "gatsby"
import styled from "styled-components"
import { CompanyLogo } from "../../lib/componentHelpers"
import { withFirebase } from "./firebase"
import * as ROUTES from "../../constants/routes"

const JoinDiv = styled.div`
  margin-top: 0;
  width: 100%;
  height: 100%;
  margin-top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #19232d;
`

const Form = styled.form`
  margin-top: 2em;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #19232d;
`

const JoinFormHeader = styled.div`
  height: 93px;
  width: 82.67%;
  font-family: Noe Display;
  font-size: 20px;
  font-weight: bold;
  line-height: 31px;
  text-align: center;
  color: #ffffff;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
`

const InputDiv = styled.div`
  width: 82.67%;
  margin-bottom: 2em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`

const Input = styled.input`
  margin-top: 1em;
  width: 100%;
  height: 33px;
  border: none;
  font-size: 16px;
  font-family: "overpass";
  color: #ffffff;
  background-color: transparent;
  border-bottom: 2px solid #262c47;
  outline: none;
  ::placeholder {
    color: #ffffff;
  }
`

const PasswordInput = styled.input`
  margin-top: 1em;
  width: 100%;
  height: 33px;
  border: none;
  font-size: 16px;
  color: #f33a3a;
  font-family: "overpass";
  border-bottom: 2px solid #262c47;
  outline: none;
  background-color: transparent;
  ::placeholder {
    color: #ffffff;
  }
`

const Button = styled.button`
  width: 82.67%;
  height: 50px;
  background-color: #fbffa0;
  color: #19232d;
  font-size: 15px;
  font-weight: bold;
  border: 3px solid #19232d;
  text-transform: uppercase;
  padding: 10px;
  align-self: center;
  outline: none;
  &:hover {
    cursor: pointer;
    border-color: #19232d;
    background-color: #ffffff;
  }
`

const LinkDiv = styled.div`
  width: 100%;
  height: 60px;
  font-family: Overpass;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0 auto;
`

const SignLink = styled(Link)`
  text-decoration: none;
`

const SignUpP = styled.p`
  &:hover {
    text-decoration: underline;
    color: #ffffff;
  }
`

const SpanUp1 = styled.span`
  color: #ffffff;
`

const SpanUp2 = styled.span`
  color: #9bc2e5;
`

const BSLogoDiv = styled.div`
  margin-top: 7em;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (min-width: 560px) {
    display: none;
  }
`

const INITIAL_STATE = {
  firstName: "",
  lastName: "",
  email: "",
  username: "",
  passwordOne: "",
  passwordTwo: "",
  password: "",
  isAdmin: false,
  error: null
}

const accountAlreadyInUseErrorCode = "auth/email-already-in-use"
const accountAlreadyInUseErrorMessage = `Ein Konto mit dieser E-Mail-Adresse ist bereits vorhanden.`

class SignUpFormBase extends Component {
  constructor(props) {
    super(props)

    this.state = { ...INITIAL_STATE }
  }

  handleSubmit = e => {
    e.preventDefault()

    const { firstName, lastName, username, email, password, passwordOne, passwordTwo } = this.state

    const roles = {}

    firstName && lastName
      ? this.setState({
          username: `${firstName} ${lastName}`
        })
      : this.setState({
          error: `Bitte geben Sie Ihren Vor- und Nachnamen an`
        })

    if (!passwordOne || !passwordTwo)
      this.setState({
        error: `Bitte füllen Sie die Passwortfelder aus`
      })

    if (!email)
      this.setState({
        error: `Bitte füllen Sie das E-Mail-Feld aus`
      })

    passwordOne === passwordTwo
      ? this.setState({
          password: passwordOne
        })
      : this.setState({
          error: `Die eingegebenen Passwörter stimmen nicht überein`
        })

    if (password && username) {
      this.props.firebase
        .doCreateUserWithEmailAndPassword(email, password)
        .then(authUser => {
          // Create a user in your Firebase realtime database
          return this.props.firebase.user(authUser.user.uid).set({
            displayName: username,
            username,
            email,
            roles
          })
        })
        .then(() => {
          return this.props.firebase.doSendEmailVerification()
        })
        .then(() => {
          this.setState({ ...INITIAL_STATE })
          navigate(ROUTES.HOME)
        })
        .catch(err => {
          if (err.code === accountAlreadyInUseErrorCode) {
            this.setState({ error: accountAlreadyInUseErrorMessage })
          }

          this.setState({ error: err.message })
        })
    }
  }

  handleChange = ({ target }) => {
    this.setState({ [target.name]: target.value })
  }

  render() {
    const { firstName, lastName, email, passwordOne, passwordTwo, error } = this.state
    return (
      <JoinDiv>
        <Form onSubmit={e => this.handleSubmit(e)}>
          {error ? (
            <JoinFormHeader>{error}</JoinFormHeader>
          ) : (
            <JoinFormHeader>
              Jetzt registrieren und persönliche Empfehlungen erhalten
            </JoinFormHeader>
          )}
          <InputDiv>
            <Input
              name="firstName"
              type="text"
              placeholder="Vorname"
              data-input-id="firstName"
              value={firstName}
              onChange={this.handleChange}
              autoComplete="firstname"
            />
            <Input
              name="lastName"
              type="text"
              placeholder="Nachname"
              data-input-id="lastName"
              value={lastName}
              onChange={this.handleChange}
              autoComplete="lastname"
            />
            <Input
              name="email"
              type="text"
              placeholder="Email"
              data-input-id="email"
              value={email}
              onChange={this.handleChange}
              autoComplete="email"
            />
            <PasswordInput
              name="passwordOne"
              type="password"
              placeholder="Passwort"
              data-input-id="passwordOne"
              value={passwordOne}
              onChange={this.handleChange}
              autoComplete="first-new-password"
            />
            <PasswordInput
              name="passwordTwo"
              type="password"
              placeholder="Passwort wiederholen"
              data-input-id="passwordTwo"
              value={passwordTwo}
              onChange={this.handleChange}
              autoComplete="second-new-password"
            />
          </InputDiv>
          <Button type="submit">registrieren</Button>
        </Form>
        <BSLogoDiv>
          <CompanyLogo />
        </BSLogoDiv>
      </JoinDiv>
    )
  }
}

const SignUpForm = withFirebase(SignUpFormBase)

const SignUpLink = () => (
  <LinkDiv>
    <SignLink to={ROUTES.SIGN_UP}>
      <SignUpP data-p-id="signUpLink">
        <SpanUp1>
          Noch keinen Account?
          <SpanUp2> Jetzt registrieren</SpanUp2>
        </SpanUp1>
      </SignUpP>
    </SignLink>
  </LinkDiv>
)

const ForgetPasswordLink = () => (
  <LinkDiv>
    <SignLink to={ROUTES.PASSWORD_FORGET}>
      <SignUpP data-p-id="forgetPasswordLink">
        <SpanUp1>
          Passwort Vergessen?
          <SpanUp2>Jetzt zurücksetzen</SpanUp2>
        </SpanUp1>
      </SignUpP>
    </SignLink>
  </LinkDiv>
)

const UserRegistrationLink = () => (
  <LinkDiv>
    <SignLink to={ROUTES.SIGN_UP}>
      <SignUpP data-p-id="userRegistrationLink">
        <SpanUp1>
          Noch keinen Account?
          <SpanUp2> Jetzt anmelden</SpanUp2>
        </SpanUp1>
      </SignUpP>
    </SignLink>
  </LinkDiv>
)

export { SignUpForm, SignUpLink, ForgetPasswordLink, UserRegistrationLink }
