import React, { Component } from "react"
import { navigate } from "gatsby"
import styled from "styled-components"
import { withFirebase } from "./firebase"
import { SignUpLink, ForgetPasswordLink, UserRegistrationLink } from "./join"
import { CompanyLogo } from "../../lib/componentHelpers"
import AnonymousLogo from "../../assets/anonymous-white.svg"
import GoogleLogo from "../../assets/google-white.svg"
import FacebookLogo from "../../assets/facebook-white.svg"
import * as ROUTES from "../../constants/routes"
import "../../styles/layout2.module.css"

const LoginDiv = styled.div`
  margin-top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #19232d;
`

const Form1 = styled.form`
  margin-top: 2em;
  width: 82.67%;
  display: flex;
  flex-direction: column;
  background-color: transparent;
`

const FormHeader = styled.div`
  height: 113px;
  font-family: Noe Display;
  font-size: 20px;
  font-weight: bold;
  color: #ffffff;
  line-height: 31px;
  text-align: center;
  background-color: transparent;
`

const FormErrorHeader = styled.div`
  height: 113px;
  font-family: Noe Display;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  background-color: transparent;
  color: #ffffff;
`

const InputDiv = styled.div`
  width: 100%;
  height: 80px;
  margin-bottom: 1em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
`

const Input = styled.input`
  width: 95%;
  border: none;
  font-size: 16px;
  color: #ffffff;
  font-family: "overpass";
  border-bottom: 2px solid #262c47;
  outline: none;
  padding-left: 5px;
  background-color: transparent;
  ::placeholder {
    color: #ffffff;
  }
`

const PasswordInput = styled.input`
  width: 95%;
  border: none;
  font-size: 16px;
  color: #f33a3a;
  font-family: "overpass";
  border-bottom: 2px solid #262c47;
  outline: none;
  padding-left: 5px;
  background-color: transparent;
  ::placeholder {
    color: #ffffff;
  }
`

const Button = styled.button`
  width: 100%;
  height: 50px;
  background-color: #fbffa0;
  color: #19232d;
  font-size: 15px;
  font-weight: bold;
  align-self: center;
  border: 2px solid #fbffa0;
  text-transform: uppercase;
  padding: 10px;
  outline: none;
  &:hover {
    cursor: pointer;
    border-color: #19232d;
    background-color: #ffffff;
  }
`

const LinkDiv = styled.div`
  width: 68.53%;
`

const SocialDiv = styled.div`
  width: 82.67%;
  background-color: #19232d;
`

const Form = styled.form`
  margin-top: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
`

const AnonymousButton = styled.button`
  margin-top: 1em;
  height: 50px;
  display: flex;
  flex-flow: column wrap;
  width: 100%;
  justify-content: space-around;
  font-family: "Overpass";
  font-size: 16px;
  font-weight: 600;
  background-color: #9755fc;
  color: #ffffff;
  border: 2px solid #9755fc;
  text-transform: uppercase;
  padding: 0;
  outline: none;
  &:hover {
    cursor: pointer;
    border-color: #19232d;
  }
`

const GoogleButton = styled.button`
  margin-top: 1em;
  height: 50px;
  display: flex;
  flex-flow: column wrap;
  width: 100%;
  justify-content: space-around;
  font-family: "Overpass";
  font-size: 16px;
  font-weight: 600;
  color: #19232d;
  background-color: #ffffff;
  border: 2px solid #ffffff;
  text-transform: uppercase;
  padding: 0;
  outline: none;
  &:hover {
    cursor: pointer;
    border-color: #19232d;
  }
`
const FacebookButton = styled.button`
  margin-top: 1em;
  height: 50px;
  display: flex;
  flex-flow: column wrap;
  width: 100%;
  justify-content: space-around;
  font-family: "Overpass";
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  background-color: #2e4682;
  border: 2px solid #2e4682;
  text-transform: uppercase;
  padding: 0;
  outline: none;
  &:hover {
    cursor: pointer;
    border-color: #19232d;
  }
`

const LogoDiv1 = styled.div`
  height: 100%;
  width: 33px;
  display: flex;
  align-items: center;
  justify-content: start;
`
const LogoDiv2 = styled.div`
  height: 100%;
  width: 33px;
  display: flex;
  align-items: center;
  justify-content: start;
`
const LogoDiv3 = styled.div`
  height: 100%;
  width: 33px;
  display: flex;
  align-items: center;
  justify-content: start;
`

const DetailsDiv = styled.div`
  height: 100%;
  align-items: center;
  justify-items: center;
  display: flex;
`

const Image = styled.img`
  width: 33px;
  height: 25px;
`

const OderDiv = styled.div`
  width: 82.67%;
  display: flex;
  color: #ffffff;
  margin-top: 0.5em;
`

const OderHr = styled.hr`
  width: 42%;
  height: 2px;
  border: none;
  background-color: #262c47;
`

const OderSpan = styled.span`
  font-size: 14px;
  font-weight: 600;
  font-family: overpass;
`

const SocialP = styled.div`
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  font-family: Noe Display;
  font-size: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #f33a3a;
`

const BSLogoDiv = styled.div`
  margin-top: 1.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (min-width: 675px) {
    display: none;
  }
`

const INITIAL_STATE = {
  email: "",
  password: "",
  error: null
}

const accountExistErrorCode = "auth/account-exists-with-different-credential"
const accountExistErrorMessage = `Ein Konto mit einer E-Mail-Adresse für dieses soziale Konto ist bereits vorhanden.`

const invalidPassword = `auth/wrong-password`
const invalidPasswordMessage = `Leider ist das eingegebene Passwort nicht korrekt.`

const invalidUserEmail = `auth/user-not-found`
const invalidUserEmailMessage = `Unter dieser Email ist bei uns kein Account registriert.`

const invalidCredential = `auth/invalid-credential`
const invalidCredentialMessage = `Fehler beim Abrufen des Bestätigungscodes aus den sozialen Medien.`

const setUserDetails = (socialAuthUser, data) => {
  // Create a user in your Firebase Realtime Database too
  return data.user(socialAuthUser.user.uid).set({
    displayName: socialAuthUser.additionalUserInfo.profile.name,
    username: socialAuthUser.additionalUserInfo.profile.name,
    email: socialAuthUser.additionalUserInfo.profile.email
  })
}

class SignInFormBase extends Component {
  constructor(props) {
    super(props)
    this.state = { ...INITIAL_STATE }
  }

  handleFormSubmit = e => {
    e.preventDefault()
    const { email, password } = this.state
    this.props.firebase
      .useEmailAndPasswordToSignIn(email, password)
      .then(() => {
        this.setState({ ...INITIAL_STATE })
        navigate(ROUTES.QUIZ)
      })
      .catch(err => {
        if (err.code === invalidUserEmail) {
          this.setState({
            error: invalidUserEmailMessage
          })
        }
        if (err.code === invalidPassword) {
          this.setState({
            error: invalidPasswordMessage
          })
        }
      })
  }

  handleInputChange = ({ target }) => {
    this.setState({ [target.name]: target.value })
  }

  render() {
    const { email, password, error } = this.state
    return (
      <LoginDiv>
        <Form1 onSubmit={this.handleFormSubmit}>
          {error ? (
            <FormErrorHeader>{error}</FormErrorHeader>
          ) : (
            <FormHeader className="loginHeadingDiv">
              Melde dich jetzt an, um deine Ergebnisse per Email zu erhalten
            </FormHeader>
          )}
          <InputDiv>
            <Input
              name="email"
              type="email"
              placeholder="Email"
              data-input-id="email"
              value={email}
              onChange={this.handleInputChange}
              autoComplete="email"
            />
            <PasswordInput
              name="password"
              type="password"
              placeholder="Passwort"
              data-input-id="password"
              value={password}
              onChange={this.handleInputChange}
              autoComplete="current-password"
            />
          </InputDiv>
          <Button data-button-id="loginButton" type="submit">
            Login
          </Button>
        </Form1>

        <LinkDiv>
          {error === `Leider ist das eingegebene Passwort nicht korrekt.` ? (
            <ForgetPasswordLink />
          ) : error === `Unter dieser Email ist bei uns kein Account registriert.` ? (
            <UserRegistrationLink />
          ) : (
            <SignUpLink />
          )}
        </LinkDiv>

        <OderDiv>
          <OderHr />
          <OderSpan>oder</OderSpan>
          <OderHr />
        </OderDiv>
        <SignInGoogle />
        <SignInFacebook />
        <AnonymousSignIn />
        <BSLogoDiv>
          <CompanyLogo />
        </BSLogoDiv>
      </LoginDiv>
    )
  }
}
class AnonymousSignInBase extends Component {
  constructor(props) {
    super(props)
    this.state = {
      error: ""
    }
  }

  handleAnonymousSignIn = e => {
    e.preventDefault()
    this.props.firebase
      .anonymouslySignIn()
      .then(() => {
        navigate(ROUTES.QUIZ)
      })
      .catch(err => this.setState({ error: err.message }))
  }

  render() {
    const { error } = this.state
    return (
      <SocialDiv>
        <Form>
          {error && <SocialP>{error}</SocialP>}
          {!error && (
            <AnonymousButton onClick={this.handleAnonymousSignIn}>
              <LogoDiv1>
                <Image src={AnonymousLogo} alt="anonymous-logo" />
              </LogoDiv1>
              <DetailsDiv id="loginAsGuest" data-span-id="loginAsGuest">
                Weiter ALS Gast
              </DetailsDiv>
            </AnonymousButton>
          )}
        </Form>
      </SocialDiv>
    )
  }
}

class SignInGoogleBase extends Component {
  constructor(props) {
    super(props)
    this.state = { error: null }
  }

  handleGoogleSignIn = async e => {
    try {
      e.preventDefault()
      const socialAuthUser = await this.props.firebase.useGoogleToSignIn()
      setUserDetails(socialAuthUser, this.props.firebase)
      navigate(ROUTES.QUIZ)
    } catch (err) {
      if (err.code === accountExistErrorCode) {
        this.setState({ error: accountExistErrorMessage })
      } else if (err.code === invalidCredential) {
        this.setState({ error: invalidCredentialMessage })
      } else {
        this.setState({ error: err.message })
      }
    }
  }

  render() {
    const { error } = this.state
    return (
      <SocialDiv>
        <Form>
          {error && <SocialP>{error}</SocialP>}
          {!error && (
            <GoogleButton onClick={this.handleGoogleSignIn}>
              <LogoDiv2>
                <Image src={GoogleLogo} alt="google-logo" />
              </LogoDiv2>
              <DetailsDiv id="loginMitGoogle" data-span-id="loginMitGoogle">
                Weiter mit Google
              </DetailsDiv>
            </GoogleButton>
          )}
        </Form>
      </SocialDiv>
    )
  }
}

class SignInFacebookBase extends Component {
  constructor(props) {
    super(props)
    this.state = { error: null }
  }

  handleFacebookSignIn = async e => {
    try {
      e.preventDefault()
      const socialAuthUser = await this.props.firebase.useFacebookToSignIn()
      setUserDetails(socialAuthUser, this.props.firebase)
      navigate(ROUTES.QUIZ)
    } catch (err) {
      if (err.code === accountExistErrorCode) {
        this.setState({ error: accountExistErrorMessage })
      } else if (err.code === invalidCredential) {
        this.setState({ error: invalidCredentialMessage })
      } else {
        this.setState({ error: err.message })
      }
    }
  }

  render() {
    const { error } = this.state
    return (
      <SocialDiv>
        <Form>
          {error && <SocialP>{error}</SocialP>}
          {!error && (
            <FacebookButton onClick={this.handleFacebookSignIn}>
              <LogoDiv3>
                <Image src={FacebookLogo} alt="facebook-logo" />
              </LogoDiv3>
              <DetailsDiv id="loginMitFacebook" data-span-id="loginMitFacebook">
                Login mit Facebook
              </DetailsDiv>
            </FacebookButton>
          )}
        </Form>
      </SocialDiv>
    )
  }
}

const SignInForm = withFirebase(SignInFormBase)
const SignInGoogle = withFirebase(SignInGoogleBase)
const SignInFacebook = withFirebase(SignInFacebookBase)
const AnonymousSignIn = withFirebase(AnonymousSignInBase)

export default SignInForm
export { SignInGoogle, SignInFacebook, AnonymousSignIn }
