import React from "react"
import Layout2 from "../components/main/layout2"
import { StaticQuery, graphql } from "gatsby"
import SignInForm from "../components/main/login"
import styled from "styled-components"
import { CompanyLogo } from "../lib/componentHelpers"

const query = graphql`
  query AffiliateSigninQuery {
    siteSettings: allSanitySiteSettings {
      edges {
        node {
          affiliate {
            title
            link
            logo {
              asset {
                _id
                fluid {
                  src
                }
                metadata {
                  dimensions {
                    aspectRatio
                    height
                    width
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

const SigninWrapper = styled.div`
  background-color: #19232d;
  width: 100%;
  padding-bottom: 2em;
  min-height: 100vh;
`

const BSLogoDiv = styled.div`
  margin-top: 1.5em;
  display: flex;
  justify-content: center;
  align-items: center;
`

const AffiliateOuterDiv = styled.div`
  margin: 50px auto 0 auto;
  width: 560px;
  @media (max-width: 559px) {
    width: 375px;
    margin: 40px auto 0 auto;
  }
`

const AffiliateTitle = styled.h3`
  font-family: Overpass;
  font-weight: 600;
  font-size: 17px;
  line-height: 100%;
  text-transform: uppercase;
  margin-bottom: 38px;
  text-align: center;
  color: #f8ffa0;
  @media (max-width: 559px) {
    font-size: 15px;
    line-height: 100%;
  }
`

const AffiliateInnerDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
`

const AffiliateLink2 = styled.a`
  display: block;
  text-decoration: none;
  outline: none;
  &:active {
    outline: none;
  }
`

const DesktopContainer = styled.div`
  display: none;
  margin: 0 10px 15px 10px;
  @media (min-width: 560px) {
    display: block;
  }
`

const MobileContainer = styled.div`
  display: block;
  margin: 0 10px 15px 10px;
  @media (min-width: 560px) {
    display: none;
  }
`

const AffiliateLogo = props => {
  const {
    partner: { link, logo, title }
  } = props
  const aspectRatio = logo.asset.metadata.dimensions.aspectRatio
  const widthDesktop = `${50 * aspectRatio}px`
  const widthMobile = `${35 * aspectRatio}px`
  return (
    <AffiliateLink2 href={link} target="_blank" rel="noopener noreferrer">
      <DesktopContainer>
        <img
          src={logo.asset.fluid.src}
          alt={title}
          loading="lazy"
          style={{ width: widthDesktop, height: "50px" }}
        />
      </DesktopContainer>
      <MobileContainer>
        <img
          src={logo.asset.fluid.src}
          alt={title}
          loading="lazy"
          style={{ width: widthMobile, height: "35px" }}
        />
      </MobileContainer>
    </AffiliateLink2>
  )
}

const AffiliateContainer = props => {
  const { affiliatePartners } = props
  return (
    <AffiliateOuterDiv>
      <AffiliateTitle>Unsere Partner</AffiliateTitle>
      <AffiliateInnerDiv>
        {affiliatePartners &&
          affiliatePartners.length > 0 &&
          affiliatePartners.map((partner, index) => (
            <AffiliateLogo partner={partner} key={index} />
          ))}
      </AffiliateInnerDiv>
    </AffiliateOuterDiv>
  )
}

const LoginPage = () => {
  return (
    <SigninWrapper>
      <Layout2>
        <SignInForm />
      </Layout2>
      <BSLogoDiv>
        <CompanyLogo />
      </BSLogoDiv>
      <StaticQuery
        query={query}
        render={data => {
          const { siteSettings } = data
          const { edges } = siteSettings
          const [outerNode] = edges
          const { node: innerNode } = outerNode
          const { affiliate: affiliatePartners } = innerNode
          return (
            <>
              {affiliatePartners && affiliatePartners.length > 0 ? (
                <AffiliateContainer affiliatePartners={affiliatePartners} />
              ) : null}
            </>
          )
        }}
      />
    </SigninWrapper>
  )
}

export default LoginPage
